import { useEffect } from "react";
import {
  AiFillFacebook,
  AiFillInstagram,
  AiOutlineGithub,
  AiOutlineGooglePlus,
} from "react-icons/ai";
import { FaPhoneAlt } from "react-icons/fa";
import { allStrings } from "../../commons";
import { getUserDetails } from "../../utills/helpers/AuthHelper";
// import { GoogleLogin } from "react-google-login";
import { InstagramLogin } from "@amraneze/react-instagram-login";

import "./style.scss";
import { instagramProfile } from "../../utills/rest-apis/ApiHandeling";
const AllLogin = ({ changeModalScreen }) => {
  // const google = () => {
  //   window.open("http://localhost:5000/auth/google", "_self");
  // };

  const github = () => {
    window.open("http://localhost:5000/auth/github", "_self");
  };

  const facebook = () => {
    window.open("http://localhost:5000/auth/facebook", "_self");
  };

  const phone = () => {
    changeModalScreen(allStrings.modalScreens.allLogin);
  };

  function onSignIn(googleUser) {
    var profile = googleUser.getBasicProfile();
    console.log("ID: " + profile.getId()); // Do not send to your backend! Use an ID token instead.
    console.log("Name: " + profile.getName());
    console.log("Image URL: " + profile.getImageUrl());
    console.log("Email: " + profile.getEmail()); // This is null if the 'email' scope is not present.
  }

  const handleCallbackResponse = (response) => {
    const x = response;
    console.log("Encoded JWT ID TOKEN", { x });
  };

  const facebookLogin = () => {
    window.FB.getLoginStatus(function (response) {
      console.log("login status", response);
      if (response.status != "connected") {
        window.FB.login(function (response) {
          if (response.authResponse) {
            console.log("Welcome!  Fetching your information.... ");
            window.FB.api('/me', 'get', { fields: 'id,name,gender,email,picture,birthday' }, function (response) {
              debugger;
              console.log("Good to see you, " + response.name + ".");
              window.location.href = "https://m.facebook.com/?soft=composer";
            });
          } else {
            console.log("User cancelled login or did not fully authorize.");
          }
        });
      } else if (response.status == "connected") {
        window.location.href = "https://m.facebook.com/?soft=composer";
      }
    });
  };
  window.FB.getLoginStatus(function (response) {
    console.log("login status", response);
    // statusChangeCallback(response);
  });
  // },[])
  // useEffect(() => {
  //   console.log(
  //     getUserDetails(
  //       "eyJhbGciOiJSUzI1NiIsImtpZCI6IjE3MjdiNmI0OTQwMmI5Y2Y5NWJlNGU4ZmQzOGFhN2U3YzExNjQ0YjEiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL2FjY291bnRzLmdvb2dsZS5jb20iLCJuYmYiOjE2NjA3NjYyNTksImF1ZCI6IjI5MTI5Mzc1MjEyMS10OWgxYXVzYmNiYWNzc29xcTZtdXE2MDNta3ZwNmplaS5hcHBzLmdvb2dsZXVzZXJjb250ZW50LmNvbSIsInN1YiI6IjExODM0Mzk0MTI5OTExMTg2Mjk1MCIsImVtYWlsIjoiYW5lZXFhc2doYXIyMEBnbWFpbC5jb20iLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiYXpwIjoiMjkxMjkzNzUyMTIxLXQ5aDFhdXNiY2JhY3Nzb3FxNm11cTYwM21rdnA2amVpLmFwcHMuZ29vZ2xldXNlcmNvbnRlbnQuY29tIiwibmFtZSI6IkFuZWVxIEFzZ2hhciIsInBpY3R1cmUiOiJodHRwczovL2xoMy5nb29nbGV1c2VyY29udGVudC5jb20vYS9BSXRidm1sb2dldmh2M2pWWUJaX2lhRHREVjI0cy1MWTZYNXhVQlZpeERtdD1zOTYtYyIsImdpdmVuX25hbWUiOiJBbmVlcSIsImZhbWlseV9uYW1lIjoiQXNnaGFyIiwiaWF0IjoxNjYwNzY2NTU5LCJleHAiOjE2NjA3NzAxNTksImp0aSI6ImFlOGY0NWM1MDZhZmQyZjFjZjA4YTQyYTE1YjQxMTcyMGE1MGFiYjEifQ.Spn7NorqkY3CvM5aDVMHTk2sAH8VK0r1PJ7XCYjqIX3ioDVtSdxziO-IKex9sZ1bfsaRRhQffnn_iBGMOvv9XzxwIO7h2t7Bz9iQdVKgjZeGrhf100MqQUODvbg2FL0sbe7Dum7zVgHPs0d7_Kcc7UeVEFfviJg6EadxlhpSH6hCsoQfG_kE6HEPZOjZf3Lc9gYUFbfSQWNZqabp0TqRpAiq-17v0vKvURgd-23mSWY7oukYOxEsKYok37TghP1mFNvkKHlT0SsZ6JrXtnK9JThv3-YieXQzoyYyIbzlhYEsBUiTsSIoeXMSN584aNYta-RuYnD_13zlQ8EH4fny5Q"
  //     )
  //   );
  // });
  useEffect(() => {
    /* global google */
    google.accounts.id.initialize({
      client_id:
        "291293752121-t9h1ausbcbacssoqq6muq603mkvp6jei.apps.googleusercontent.com",
      callback: handleCallbackResponse,
    });

    google.accounts.id.renderButton(document.getElementById("signInDiv"), {
      theme: "outline",
      size: "large",
    });
  }, []);
  const responseSuccess = (response) => {
    console.log("success", response);
  };

  const responseFailure = (response) => {
    console.log("fail", response);
  };

  const onSuccess = (res) => {
    console.log('Login Success: currentUser:', res.profileObj);
    alert(
      `Logged in successfully welcome ${res.profileObj.name} 😍. \n See console for full profile object.`
    );
    // refreshTokenSetup(res);
  };

  // const onFailure = (res) => {
  //   console.log('Login failed: res:', res);
  //   alert(
  //     `Failed to login. 😢 Please ping this to repo owner twitter.com/sivanesh_fiz`
  //   );
  // };
  const clientId =
    "291293752121-t9h1ausbcbacssoqq6muq603mkvp6jei.apps.googleusercontent.com";

  const res = (res) => {
    console.log(res);
  };

  const resInsta = (res) => {
    window.location.href = "https://www.instagram.com/create/style/";
  };

  const fetchus = () => {
    // instag();
    window.location.href = `https://api.instagram.com/oauth/authorize?client_id=602126718198737&redirect_uri=https://localhost:3000/&scope=user_profile,user_media&response_type=code`;
  };

  const instag = async () => {
    let apiResponse = await instagramProfile();
    console.log("instagram response", apiResponse);

    debugger;
    // try {
    //   Animate();
    //   if (apiResponse?.success === false) {
    //     let updatedValue = {};
    //     updatedValue = {
    //       message: `${apiResponse?.description}!`,
    //       type: apiResponse.success
    //         ? allStrings.apiResponseType.success
    //         : allStrings.apiResponseType.error,
    //     };
    //     setAlertData({ ...alertData, ...updatedValue });
    //   } else {
    //     changeModalScreen(allStrings.modalScreens.verifyAccount, openModal);
    //   }
    // } catch (error) {}
  };

  return (
    <div>
      <div className="socialLogin">
        <div className="log">LOGIN </div>
        via
      </div>
      {/* <GoogleLogin
        clientId="291293752121-t9h1ausbcbacssoqq6muq603mkvp6jei.apps.googleusercontent.com"
        buttonText="Login"
        onSuccess={responseSuccess}
        onFailure={responseFailure}
        cookiePolicy={"single_host_origin"}
      /> */}
      {/* <GoogleLogin
        clientId={clientId}
        buttonText="Login"
        onSuccess={onSuccess}
        onFailure={onFailure}
        cookiePolicy={"single_host_origin"}
        style={{ marginTop: "100px" }}
        isSignedIn={true}
      /> */}
      <div id="signInDiv"></div>
      {/* <div class="g-signin2" data-onsuccess="onSignIn"></div> */}

      {/* <div className="flex-container google">
        <span>
          <AiOutlineGooglePlus size={50} />
        </span>
        <span>Google</span>
      </div> */}

      {/* <div className="flex-container facebook">
        <span>
          <AiFillFacebook size={50} />
        </span>
        <span>Facebook</span>
      </div> */}

      <div className="flex-container i">
        <span>
          <AiFillInstagram size={50} />
        </span>
        <span>Instagram</span>
      </div>

      <InstagramLogin
        clientId="602126718198737"
        buttonText="Login"
        onSuccess={resInsta}
        useRedirect
        onFailure={res}
        redirectUri="https://localhost:3000/"
      />

      {/* <div className="flex-container github">
        <span>
          <AiOutlineGithub size={50} />
        </span>
        <span>GitHub</span>
      </div> */}

      {/* <div className="flex-container phone" onClick={phone}>
        <span>
          <FaPhoneAlt size={50} />
        </span>
        <span>Phone</span>
      </div> */}

      <button onClick={fetchus}>fff</button>

      <div className="flex-container facebook" onClick={facebookLogin}>
        <span>
          <AiFillFacebook size={50} />
        </span>
        <span>Facebook</span>
      </div>
    </div>
  );
};

export default AllLogin;
