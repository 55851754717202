const allStrings = {
  title: "WebApp",
  welcomHeading: "Welcome to ",
  catagoryOne: "FOOD",
  catagoryTwo: "TOYS",
  catagoryThree: "VENDORWEB1",
  catagoryFour: "VENDORWEB2",
  productDescription: "CHOCOLATE SHAKE with orio and vanila milk",
  notAvaialble: "VENDOR NOT FOUND",
  instruction: "Please add your specific instruction here ",
  Sections: "Section Number : ",
  Location: "Please select location",
  subtotal: "Subtotal",
  GrandTotal: "Grand Total",
  Tax: "Tax",
  ContinuetoPayment: "Continue to Payment",
  delet: "Do you want to remove this item from your cart?",
  Address: "Enter your address",
  editAddress: "Edit  your address",
  empty: "Cart is empty",
  selectButton: "SELECT",
  subTotalText: "Subtotal:",
  confirmationText:
    "You will receive a text message for your order confirmation.",
  thank: "Thank you ",
  description: "DESCRIPTION:",
  price: "PRICE:",
  orderButton: "ORDER",
  options: "OPTIONS:  ",
  addons: "ADDONS:",
  quantity: "Quantity:",
  row: "Row",
  section: "Section",
  submitButton: "Submit",
  homeText: "HOME",
  cartText: "CART",
  SectionsChose: "CHOSE YOUR SECTION",
  disable: "OUT OF STOCK",
  Quantity: "Quantity",
  Home: "Home",
  Login: "LOGIN",
  loggedIn: "Successfully Logged In!",
  success: "Sucessfully Added item to the cart!",
  COMPLETED: "COMPLETED",
  Special_Instructions: "Special Instructions",
  deletAll: "Do you want to remove all items from the cart?",
  emptyCartText: "Empty Cart",
  checkoutText: "checkout",
  yes: "Yes",
  no: "No",
  okay: "Okay",
  submit: "Submit",
  submitAddressText: " Please Submit Address",
  stripePublicKey:
    "pk_test_51J45GPDnAZeGFPBtbGXApmveYfON0wyYEDx1oSVkJygRPkFg0kNWJRjv5ebYfktAtKUCh71Yofc6dWNUK9aPoeTG001DA0DQsG",
  stripeButton: "Proceed to checkout",
  forgetPassword: "Forget Password?",
  forgotPassword: "Forgot Password",
  verifyAccount: "Verify Account",
  sendCode: "Send Code",
  enterCode: "Enter Code",
  checkin: "Check-in",
  signIn: "Sign in",
  signup: "Sign Up",
  modalScreens: {
    login: "login",
    forgetPassword: "forgetPassword",
    resetCodeForm: "resetCodeForm",
    createPassword: "createPassword",
    cancelOrder: "cancelOrder",
    feedbackForm: "feedbackForm",
    redeemOrder: "redeemOrder",
    createAccount: "createAccount",
    venues: "multipleVenues",
    rewards: "multipleRewards",
    reqSection: "requestSection",
  },
  rewards: "Rewards",
  history: "History",
  cancelOrder: "Cancel Order",
  cancelQuestion: "Cancel Order?",
  cancelText: "Are you sure you want to cancel your entire order?",
  dontCancel: "Don't Cancel",
  saveChanges: "SAVE CHANGES",
  instruction: "Instructions",
  contests: "Contests",
  leaderboard: "Leaderboard",
  sampleQuestion:
    "it was sunday on jan 1, 2022. what was the day of the week jan 1, 2010?",
  question: "Question:  ",
  answer: "Answer:  ",
  feedbackText: "Provide feedback for",
  eventFeedback: "Event Feedback",
  appFeedback: "App Feedback",
  emailPlaceholder: "Email (Optional)",
  textareaPlaceholder: "Type a message",
  createNewPassword: "Create New Password",
  enterNewPassword: "Enter your new password",
  newPasswordPlaceholder: "New Password",
  confirmPasswordPlaceholder: "Confirm New Password",
  completed: "Completed",
  submitAnswer: "Submit Answer",
  successToast: {
    status: "Success!",
    message: "Your feedback was submitted successfully",
  },
  myPoints: "My Points",
  confirm: "confirm",
  teamShopCoupon: "Team Shop Coupon",
  showThisCouponToTheAttendantInTheTeamShopToRedeemYourCoupon:
    "Show this coupon to the attendant in the Team Shop to redeem your coupon",
  ok: "OK",
  title: "WebApp",
  welcomHeading: "Welcome to ",
  catagoryOne: "FOOD",
  catagoryTwo: "TOYS",
  catagoryThree: "VENDORWEB1",
  catagoryFour: "VENDORWEB2",
  productDescription: "CHOCOLATE SHAKE with orio and vanila milk",
  notAvaialble: "VENDOR NOT FOUND",
  instruction: "Please add your specific instruction here ",
  Sections: "Section Number : ",
  Location: "Please select location",
  subtotal: "Subtotal",
  GrandTotal: "Grand Total",
  Tax: "Tax",
  ContinuetoPayment: "Continue to Payment",
  delet: "Do you want to remove this item from your cart?",
  Address: "Enter your address",
  editAddress: "Edit  your address",
  empty: "Cart is empty",
  selectButton: "SELECT",
  subTotalText: "Subtotal:",
  confirmationText:
    "You will receive a text message for your order confirmation.",
  thank: "Thank you ",
  description: "DESCRIPTION:",
  price: "PRICE:",
  orderButton: "ORDER",
  options: "OPTIONS:  ",
  addons: "ADDONS:",
  quantity: "Quantity:",
  row: "Row",
  section: "Section",
  submitButton: "Submit",
  homeText: "Home",
  cartText: "Cart",
  SectionsChose: "CHOSE YOUR SECTION",
  disable: "OUT OF STOCK",
  Quantity: "Quantity",
  Home: "Home",
  Login: "LOGIN",
  loginContest: "Sign in for contests",
  Logout: "LOGOUT",
  success: "Sucessfully Added item to the cart!",
  COMPLETED: "COMPLETED",
  Special_Instructions: "Special Instructions",
  deletAll: "Do you want to remove all items from the cart?",
  emptyCartText: "Empty Cart",
  checkoutText: "checkout",
  yes: "Yes",
  no: "No",
  okay: "Okay",
  submit: "Submit",
  submitAddressText: " Please Submit Address",
  stripePublicKey:
    "pk_test_51J45GPDnAZeGFPBtbGXApmveYfON0wyYEDx1oSVkJygRPkFg0kNWJRjv5ebYfktAtKUCh71Yofc6dWNUK9aPoeTG001DA0DQsG",
  stripeButton: "Proceed to checkout",
  forgetPassword: "Forget Password?",
  forgotPassword: "Forgot Password",
  sendCode: "Send Code",
  enterCode: "Enter Code",
  checkin: "Check-in",
  continueAsGuest: "Continue as Guest",
  modalScreens: {
    allLogin: "allLogin",
    login: "login",
    forgetPassword: "forgetPassword",
    resetCodeForm: "resetCodeForm",
    createPassword: "createPassword",
    cancelOrder: "cancelOrder",
    feedbackForm: "feedbackForm",
    redeemOrder: "redeemOrder",
    createAccount: "createAccount",
    verifyAccount: "verifyAccount",
    rewards: "multipleRewards",
  },
  rewards: "Rewards",
  history: "History",
  cancelOrder: "Cancel Order",
  cancelQuestion: "Cancel Order?",
  cancelText: "Are you sure you want to cancel your entire order?",
  dontCancel: "Don't Cancel",
  saveChanges: "SAVE CHANGES",
  instruction: "Instructions",
  contests: "Contests",
  leaderboard: "Leaderboard",
  sampleQuestion:
    "it was sunday on jan 1, 2022. what was the day of the week jan 1, 2010?",
  question: "Question:  ",
  answer: "Answer:  ",
  feedbackText: "Provide feedback for",
  eventFeedback: "Event Feedback",
  appFeedback: "App Feedback",
  emailPlaceholder: "Email (Optional)",
  textareaPlaceholder: "Type a message",
  createNewPassword: "Create New Password",
  enterNewPassword: "Enter your new password",
  newPasswordPlaceholder: "New Password",
  confirmPasswordPlaceholder: "Confirm New Password",
  completed: "Completed",
  submitAnswer: "Submit Answer",
  successToast: {
    status: "Success!",
    message: "Your feedback was submitted successfully",
  },
  myPoints: "My Points",
  confirm: "confirm",
  formValidation: {
    requireFirstName: "First name is required",
    requireLastName: "Last name is required",
    requireExistingPassword: "Existing Password is required",
    requireNewPassword: "New Password is required",
    requireConfirmNewPassword: "Confirm New Password is required",
    newPasswordMismatch:
      "Confirm new password does not matches with the new password",
    oldAndNewPasswordValidation: "Existing and new password should not match",
    requirePassword: "Password field is required",
    requirePhoneNumber: "Phone number is required",
  },
  inputTypes: {
    text: "text",
    password: "password",
  },
  apiResponseType: {
    success: "success",
    error: "error",
  },
  slideDirection: {
    up: "up",
  },
  defaultCountry: "US",
  phoneNumber: "Phone number",
  currentPassword: "currentPassword",
  newPassword: "newPassword",
  confirmNewPassword: "confirmNewPassword",
  phoneLengthVerificationMessage: "Please provide the ten digit phone code.",
  staticImage: "https://nivyimages.blob.core.windows.net/images/NivyLogo.svg",
  sameInfo: "Same information as user signed in",
  paymentDetails: "PAYMENT DETAILS",
  stripe: "STRIPE",
  totalPrice: "TOTAL PRICE",
  phoneLengthVerificationErrorrMessage:
    "Please provide the ten digit verification code.",
  orderStatus: {
    pending: "pending",
  },
  location: {
    automaticLocation: "Find Location Automatically",
    manualLocation: "Find Venue Manually",
  },
  noVenue: "No Venue Found",
  venue: "Venues",
  venuePlaceHolder: "Enter Venue Name",
  apiBaseUrl: {
    develop: `https://nivyapi-develop.azurewebsites.net`,
    master: "https://nivyapi-main.azurewebsites.net",
  },
  applyRewards: "Apply Rewards",
  rewardsStatus: {
    pending: "pending",
    redeem: "redeem",
    redeemed: "redeemed",
    used: "used",
  },
  selectRewards: "Select Reward To Redeem",
  apply: "APPLY",
  discount: "Discount",
  noRewards: "No Rewards To Redeem For Current Vendor",
  sectionPlaceholder : "Enter Section ID",
  sectionInfo : "Enter Section Information"
};

const fontFamily = {
  itc: "itc avant garde gothic",
};

const colors = {
  themColor: "#0078d7",
  gray1: "#aeaeae",
  white: "#FFF",
  primaryBlue: "#171E84",
  yellow: "#FFFF00",
  red: "FF0000",
};
const customStyle = {
  content: {
    border: "0",
    borderRadius: "4px",
    bottom: "auto",
    height: "500px",
    left: "50%",
    padding: "0 20px",
    position: "fixed",
    right: "auto",
    top: "50%", // start from center
    transform: "translate(-50%, -50%)",
    width: "75%",
    maxWidth: "40rem",
    bottom: "5vh",
  },
  position: {
    fixed: "fixed",
  },
};

const images = {
  nivyImage:
    "https://nivyapp.com/wp-content/uploads/2021/09/NivyLogo_FullColor-copy.png.webp",
};
export { allStrings, colors, fontFamily, customStyle, images };
