import { memo } from "react";
import "./style.scss";
const RewardBox = ({ desc,reward, name, onClick}) => {
  return (
    <>
      <label className="container11">
        <div className={`rectangle ${reward}`}>
          <div className={`rewardWriting ${reward} ${desc}`}>{reward}</div>
        </div>
        <input type="radio" checked name={name} onClick={onClick} />
        <span className={`checkmark ${reward}`}></span>
      </label>
    </>
  );
};

export default memo(RewardBox);
