import success from "./svgs/success.svg";
import trophy from "./svgs/trophy.svg";
import coin from "./svgs/coin.svg";
import first from "./svgs/first.svg";
import second from "./svgs/second.svg";
import third from "./svgs/third.svg";
import tick from "./svgs/tick.svg";

const getSVG = {
	success,
	trophy,
	coin,
	first,
	second,
	third,
	tick,
};

export default getSVG;
